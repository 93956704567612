@import '../node_modules/bulma/bulma.sass';

body, html {
    background: #e7ecef;
}

.main-hero {
    background: rgb(34,0,59);
    background: linear-gradient(61deg, rgba(34,0,59,1) 0%, rgba(59,27,147,1) 32%, rgba(0,211,255,1) 100%);
    color: #EEE;
}

.card-price {
    background: #ffffff14;
}